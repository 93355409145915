import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import '../main.scss';
import { getSrc } from 'gatsby-plugin-image';
import Wrapper from '../components/Wrapper/Wrapper';
import BlogPostTemplate from '../components/Templates/Blog/BlogPostTemplate';
import SEO from '../components/SEO';

const BlogPost = ({ data }) => {
  const {
    html,
    excerpt,
    frontmatter: { date, featuredImage, title, tags }
  } = data.markdownRemark;

  return (
    <Wrapper>
      <SEO title={title} description={excerpt} image={getSrc(featuredImage)} article />
      <BlogPostTemplate date={date} featuredImage={featuredImage} title={title} content={html} tags={tags} />
    </Wrapper>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired
};

export default BlogPost;

export const query = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 896)
          }
        }
        title
        tags
      }
    }
  }
`;
