import React from 'react';
import PropTypes from 'prop-types';

/**
 * ! This Component does technically make the site vulnerable to XSS attacks by saving malicious code in the cms
 * * This utility Component allows you to display raw Markdown
 * @param content - this is the actual raw markdown that is being fed by the cms
 * @param isPreview - this is a flag that should only be set to true when this component is being used in the cms preview so that the content displays correctly
 */
const MarkdownContent = ({ content, isPreview }) =>
  isPreview ? (
    <section className="content">{content}</section>
  ) : (
    <section className="content" dangerouslySetInnerHTML={{ __html: content }} />
  );

MarkdownContent.propTypes = {
  content: PropTypes.node.isRequired,
  isPreview: PropTypes.bool.isRequired
};

export default MarkdownContent;
