import React from 'react';
import PropTypes from 'prop-types';

import { figure } from './BlogPostTemplate.module.scss';
import CompatibleImage from '../../utils/CompatibleImage';
import MarkdownContent from '../../utils/MarkdownContent';
import TagRoll from '../../TagRoll';

const BlogPostTemplate = ({ date, featuredImage, title, content, isPreview, tags }) => (
  <main id="container" className="container">
    <figure className={`image ${figure}`}>
      <CompatibleImage image={featuredImage} />
    </figure>
    <div className="has-text-centered mb-6">
      <p className="subtitle has-text-info">{date}</p>
      <h1 className="title is-1">{title}</h1>
    </div>
    <MarkdownContent content={content} isPreview={isPreview} />
    <TagRoll tags={tags} />
  </main>
);

BlogPostTemplate.defaultProps = {
  isPreview: false
};

BlogPostTemplate.propTypes = {
  date: PropTypes.string.isRequired,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isPreview: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default BlogPostTemplate;
