import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';

const TagRoll = ({ tags }) => (
  <div className="field is-grouped is-grouped-multiline">
    {tags.map((tag, i) => (
      <div className="control" key={i}>
        <div className="tags has-addons">
          <span className="tag is-dark">
            <FontAwesomeIcon icon={faHashtag} />
          </span>
          <span className="tag is-danger">{tag}</span>
        </div>
      </div>
    ))}
  </div>
);

TagRoll.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TagRoll;
